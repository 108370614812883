<template lang="pug">
.container.mt-10.relative
  .dotted.bottom-left
  .dotted.top-right
  .section-information
    .data-content
      h1 {{ $t('homePage.information_title') }}
      p {{ $t('homePage.information_description_1') }}
      p {{ $t('homePage.information_description_2') }}
      p {{ $t('homePage.information_description_3') }}
      .action-button
        router-link(:to="{ name: 'builder/select-template' }") {{ $t('homePage.information_button') }}
    .data-analitycs
      .box-analitycs
        .text-7xl.font-bold.text-white.mb-3 +87%
        .text-2xl.text-white.font-black {{ $t('homePage.information_posibilities') }}
      .box-analitycs
        .text-7xl.font-bold.text-white.mb-3 30x
        .text-2xl.text-white.font-black {{ $t('homePage.information_combinations') }}
    .box-image
      img(:src="`/theme/resumes/10.png`")
</template>
<script>
export default {
  name: 'sectionInformation'
};
</script>
<style lang="scss" scoped>
.section-information {
  @apply rounded-3xl shadow-brand flex bg-white overflow-hidden flex-col md:flex-row;
}

.data-content {
  @apply bg-blue-50 py-10 px-6 flex-grow flex-shrink-0 max-w-full md:max-w-md;

  h1 {
    @apply text-2xl font-bold mb-6 text-indigo-500;
  }
  p {
    @apply text-gray-600 mb-3 text-sm md:text-base;
  }

  .action-button {
    @apply rounded-lg bg-indigo-600 text-white font-bold text-2xl py-4 text-center cursor-pointer shadow-2xl mt-4;
  }
}

.data-analitycs {
  @apply flex w-full min-h-full flex-col flex-grow flex-shrink max-w-full lg:max-w-sm;

  .box-analitycs {
    @apply flex flex-col flex-grow h-full bg-green-300 items-center justify-center py-6 px-2;

    & + .box-analitycs {
      @apply bg-purple-300;
    }
  }
}

.box-image {
  max-height: 510px;
  width: 340px;
  @apply hidden flex-grow flex-shrink-0 lg:flex;
  img {
    width: 100%;
    height: 100%;
  }
}

.dotted {
  width: 350px;
  height: 350px;
  position: absolute;
  background-image: url("/theme/pattern.svg");
  z-index: -1;
  opacity: 0.1;
  &.bottom-left {
    bottom: -50px;
    left: -50px;
  }
  &.top-right {
    top: -50px;
    right: -50px;
  }
}
</style>
